import React, { useRef } from 'react';
import { ResponsiveImage, IconArrowHead } from '../../components';
import config from '../../config';
import Slider from 'react-slick';
import classNames from 'classnames';

import css from './ListingPage.module.css';

const MIN_IMAGES_FOR_SLIDER = 4;

const SliderArrow = ({ className, direction, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <IconArrowHead className={css.imageSliderArrowIcon} direction={direction} />
    </div>
  );
};

const ImageSlider = props => {
  const { rootClassName, className, images, title, currentSlide, onSetCurrentSlide } = props;
  const classes = classNames(rootClassName || className, css.imageSlider);

  // Slider settings
  const settings = {
    className: 'imageSlider',
    vertical: true,
    verticalSwiping: true,
    infinite: true,
    dots: false,
    arrows: false,
    focusOnSelect: true,
    useTransform: false,
    speed: 700,
    slidesToShow: 4,
    slidesToScroll: 1,
    beforeChange: function(currentIndex, nextIndex) {
      onSetCurrentSlide(nextIndex);
    },
  };

  // Thumbnail variants
  const { variantPrefix } = config.listing;
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];

  // Add ref to the slider so that we can
  // manipulate it
  const slider = useRef(null);
  const showSlider = images?.length > MIN_IMAGES_FOR_SLIDER;

  return (
    <div className={classes}>
      {showSlider ? (
        <>
          <SliderArrow
            className={classNames(css.imageSliderArrow, {
              [css.arrowDisabled]: !currentSlide,
            })}
            direction="up"
            onClick={() => slider?.current?.slickPrev()}
          />
          <Slider ref={slider} {...settings}>
            {images.map(image => {
              return (
                <div key={image.id.uuid} className={css.imageSliderItemContainer}>
                  <ResponsiveImage
                    rootClassName={css.imageSliderItem}
                    alt={title}
                    image={image}
                    variants={thumbnailVariants}
                  />
                </div>
              );
            })}
          </Slider>
          <SliderArrow
            className={classNames(css.imageSliderArrow, css.imageSliderNextArrow)}
            direction="down"
            onClick={() => slider?.current?.slickNext()}
          />
        </>
      ) : (
        <div className={css.noSliderContainer}>
          {images.map((image, index) => {
            const activeIndex = index === currentSlide;
            return (
              <div
                key={index}
                className={classNames(css.imageSliderItemContainer, css.noSliderItem, {
                  [css.noSliderItemActive]: activeIndex,
                })}
                onClick={() => onSetCurrentSlide(index)}
              >
                <ResponsiveImage
                  rootClassName={css.imageSliderItem}
                  alt={title}
                  image={image}
                  variants={thumbnailVariants}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ImageSlider;
