import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton, ExternalLink } from '../../components';

import css from './ListingPage.module.css';

const SectionHeading = props => {
  const { richTitle, hostLink, showContactUser, onContactUser, listingTitle, listingId } = props;

  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        <h2 className={css.title}>{richTitle}</h2>
        <div className={css.author}>
          <FormattedMessage id="ListingPage.hostedBy" values={{ name: hostLink }} />
          {showContactUser ? (
            <span className={css.contactWrapper}>
              <span className={css.separator}>•</span>
              <InlineTextButton rootClassName={css.contactLink} onClick={onContactUser}>
                <FormattedMessage id="ListingPage.contactUser" />
              </InlineTextButton>
            </span>
          ) : null}
          <span className={css.contactWrapper}>
            <span className={css.separator}>•</span>
            <ExternalLink
              href={`mailto:hello@revelo.cc?subject=Report ${listingTitle}&body=I want to report listing with id: ${listingId}`}
              className={css.contactLink}
            >
              <FormattedMessage id="ListingPage.reportListing" />
            </ExternalLink>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SectionHeading;
