import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ExpandableText } from '../../components';

import css from './ListingPage.module.css';

const BIO_COLLAPSED_LENGTH = 300;

const SectionDescriptionMaybe = props => {
  const { description } = props;
  return description ? (
    <div className={css.sectionDescription} id="description">
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.descriptionTitle" />
      </h2>
      <ExpandableText
        className={css.description}
        text={description}
        textLength={BIO_COLLAPSED_LENGTH}
      />
    </div>
  ) : null;
};

export default SectionDescriptionMaybe;
