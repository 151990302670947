import React, { Component } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, number } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { withViewport } from '../../util/contextHelpers';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
  generateListingBreadcrumbs,
  getListingFieldsFromURL,
  findDiscountCode,
  isDiscountCodeValid,
  generateShippingItem,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities, getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { addToWishList } from '../WishListPage/WishListPage.duck';
import { addToCart } from '../CartPage/CartPage.duck';
import {
  Page,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
  ProductPanel,
  Modal,
  NamedLink,
  ExternalLink,
  ShippingAddressModal,
} from '../../components';
import { EnquiryForm } from '../../forms';
import { TopbarContainer, NotFoundPage } from '../../containers';
import { sendEnquiry, fetchTransactionLineItems, setInitialValues } from './ListingPage.duck';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { addressValidations } from '../../ducks/shipping.duck';
import { editListingCategories } from '../../marketplace-custom-config.js';

import SectionImages from './SectionImages';
import SectionHeading from './SectionHeading';
import SectionHostMaybe from './SectionHostMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionReviews from './SectionReviews';
import SectionMapMaybe from './SectionMapMaybe';
import SectionListings from './SectionListings';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import Breadcrumbs from './Breadcrumbs';
import css from './ListingPage.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 768;
const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
const LISTING_RENT_PREFERENCE = 'rent';

const { UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);

    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      discountCode: null,
      showShippingAddress: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.handleOrderSubmit = this.handleOrderSubmit.bind(this);
    this.handleDiscountChange = this.handleDiscountChange.bind(this);
    this.handleAddToCart = this.handleAddToCart.bind(this);
    this.handleShippingSubmit = this.handleShippingSubmit.bind(this);
  }

  handleShippingSubmit(values) {
    const { currentUser, onUpdateProfile } = this.props;
    const { shippingAddress } = values;

    const updateProfileParams = {
      publicData: {
        shippingAddress: {
          ...shippingAddress,
          contactName: `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`,
          email: currentUser.attributes.email,
        },
      },
    };

    onUpdateProfile(updateProfileParams)
      .then(() => {
        this.handleOrderSubmit();
      })
      .catch(err => {
        // Do nothing as we handle the error
        // with redux
        console.log(err);
      });
  }

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;

    const listingId = new UUID(params.id);
    const listing = getListing(listingId);
    const listingPreference = listing?.attributes?.publicData?.preference;

    const { bookingDates, bookingData } = values;

    const discountMaybe = this.state.discountCode
      ? {
          discount: {
            ...this.state.discountCode,
          },
        }
      : {};

    const initialValues = {
      listing,
      bookingData: {
        processAlias: config.bookingProcessAlias,
        ...bookingData,
        ...discountMaybe,
      },
      bookingDates: {
        bookingStart: bookingDates.startDate,
        bookingEnd: bookingDates.endDate,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        {
          id: listing.id.uuid,
          slug: createSlug(listing.attributes.title),
        },
        {}
      )
    );
  }

  handleOrderSubmit() {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
      currentUser,
    } = this.props;

    const listingId = new UUID(params.id);
    const listing = getListing(listingId);
    const { publicData } = listing.attributes;

    const listingPreference = publicData?.preference;
    const shippingWeight = publicData?.shippingWeight;
    const shippingItem = generateShippingItem(listing);

    const discountMaybe = this.state.discountCode
      ? {
          discount: {
            ...this.state.discountCode,
          },
        }
      : {};

    const initialValues = {
      listing,
      bookingData: {
        currentUser,
        processAlias: config.productProcessAlias,
        shippingWeight,
        shippingItems: [shippingItem],
        ...discountMaybe,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        {
          id: listing.id.uuid,
          slug: createSlug(listing.attributes.title),
        },
        {}
      )
    );
  }

  handleAddToCart() {
    const { currentUser, onAddToCart, params, history } = this.props;
    const listingId = params.id;

    if (currentUser) {
      onAddToCart(listingId);
    } else {
      // Redirect to SignupPage
      const routes = routeConfiguration();
      history.push(createResourceLocatorString('SignupPage', routes, {}, {}));
    }
  }

  handleDiscountChange(values) {
    const { code } = values; // Code from <DiscountForm />
    const { currentUser, discountCodes } = this.props;

    // We are searching for the currently typed code
    // in the array of codes from redux store
    const currentlyTypedCode = findDiscountCode(discountCodes, code, currentUser);

    if (isDiscountCodeValid(currentlyTypedCode)) {
      this.setState({
        discountCode: currentlyTypedCode,
      });
    } else {
      this.setState({
        discountCode: null,
      });
    }
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry, getListing } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    // Send the correct process alias param
    const currentListing = ensureListing(getListing(listingId));
    const listingPreference = currentListing?.attributes?.publicData?.preference;
    const isRentPreference = listingPreference == LISTING_RENT_PREFERENCE;
    const processAlias = isRentPreference ? config.bookingProcessAlias : config.productProcessAlias;

    onSendEnquiry(listingId, message.trim(), processAlias)
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      history,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      reviewsLength,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      timeSlots,
      fetchTimeSlotsError,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      similarListings,
      similarListingsLoaded,
      fetchSimilarListingsInProgress,
      fetchSimilarListingsError,
      viewport,
      onAddToWishList,
      currentWishListListingId,
      addToWishListInProgress,
      addToWishListError,
      currentUserCartListingIds,
      addToCartInProgress,
      addToCartError,
      discountCodes,
      fetchDiscountCodesInProgress,
      fetchDiscountCodesError,
      updateProfileInProgress,
      currentUserHasShippingAddress,
      addressValidationsInProgress,
      addressValidationsError,
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = 'photos';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;
    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;
    const { quantity: listingStock } = currentListing?.currentStock?.attributes || {};

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const topbar = <TopbarContainer currentPage="ListingPage" />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));
    const showReviews = reviews?.length > 0;

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);
    const authorId = ensuredAuthor?.id?.uuid;

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice } = priceData(price, intl);

    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const orderSubmit = () => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;

      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        if (currentUser) {
          if (currentUserHasShippingAddress) {
            this.handleOrderSubmit();
          } else {
            this.setState({ showShippingAddress: true });
          }
        } else {
          this.handleOrderSubmit();
        }
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );

    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    );
    const bookingSubTitle = formattedPrice;

    const hostLink = (
      <NamedLink className={css.authorNameLink} name="ProfilePage" params={{ id: authorId }}>
        {authorDisplayName}
      </NamedLink>
    );

    // Main listing fields
    const listingPreference = publicData?.preference;
    const listingCategory = publicData?.category;
    const listingSubCategory = publicData?.subcategory;
    const listingWizardParams = {
      preference: listingPreference,
      category: listingCategory,
      subcategory: listingSubCategory,
    };

    // Check listing preference
    const isRentPreference = listingPreference === LISTING_RENT_PREFERENCE;

    // Generate breadcrumbs
    const listingBreadcrumbs = generateListingBreadcrumbs(editListingCategories, {
      ...listingWizardParams,
      title: title,
    });

    // Listing fields
    const listingFields = getListingFieldsFromURL(editListingCategories, listingWizardParams);

    const allowBookings = config.allowBookings;

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.contentContainer}>
              <div className={css.topContainer}>
                <Breadcrumbs breadcrumbs={listingBreadcrumbs} />
              </div>
              <div className={css.mainContent}>
                <div className={css.leftContent}>
                  <SectionImages
                    title={title}
                    listing={currentListing}
                    isOwnListing={isOwnListing}
                    editParams={{
                      id: listingId.uuid,
                      slug: listingSlug,
                      type: listingType,
                      tab: listingTab,
                      preference: listingPreference,
                      category: listingCategory,
                      subcategory: listingSubCategory,
                    }}
                    imageCarouselOpen={this.state.imageCarouselOpen}
                    onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                    handleViewPhotosClick={handleViewPhotosClick}
                    onManageDisableScrolling={onManageDisableScrolling}
                    isMobileLayout={isMobileLayout}
                  />
                  <SectionHeading
                    richTitle={richTitle}
                    hostLink={hostLink}
                    showContactUser={showContactUser}
                    onContactUser={this.onContactUser}
                    listingTitle={title}
                    listingId={currentListing.id.uuid}
                  />
                  <SectionDescriptionMaybe description={currentListing.attributes.description} />
                  <SectionFeaturesMaybe
                    intl={intl}
                    listingFields={listingFields}
                    publicData={publicData}
                  />
                  <SectionMapMaybe
                    geolocation={geolocation}
                    publicData={publicData}
                    listingId={currentListing.id}
                  />
                </div>
                <div className={css.rightContent}>
                  {isRentPreference ? (
                    <BookingPanel
                      className={css.bookingPanel}
                      currentUser={currentUser}
                      listing={currentListing}
                      isOwnListing={isOwnListing}
                      unitType={unitType}
                      onSubmit={handleBookingSubmit}
                      title={bookingTitle}
                      subTitle={bookingSubTitle}
                      authorDisplayName={authorDisplayName}
                      onManageDisableScrolling={onManageDisableScrolling}
                      timeSlots={timeSlots}
                      fetchTimeSlotsError={fetchTimeSlotsError}
                      onFetchTransactionLineItems={onFetchTransactionLineItems}
                      lineItems={lineItems}
                      fetchLineItemsInProgress={fetchLineItemsInProgress}
                      fetchLineItemsError={fetchLineItemsError}
                      description={description}
                      showContactUser={showContactUser}
                      onContactUser={this.onContactUser}
                      allowBookings={allowBookings}
                      handleDiscountChange={this.handleDiscountChange}
                      discountCode={this.state.discountCode}
                      discountCodes={discountCodes}
                      fetchDiscountCodesInProgress={fetchDiscountCodesInProgress}
                      fetchDiscountCodesError={fetchDiscountCodesError}
                    />
                  ) : (
                    <ProductPanel
                      className={css.productPanel}
                      currentUser={currentUser}
                      listing={currentListing}
                      isOwnListing={isOwnListing}
                      unitType={unitType}
                      onOrderSubmit={orderSubmit}
                      title={bookingTitle}
                      subTitle={bookingSubTitle}
                      authorDisplayName={authorDisplayName}
                      onManageDisableScrolling={onManageDisableScrolling}
                      description={description}
                      showContactUser={showContactUser}
                      onContactUser={this.onContactUser}
                      listingStock={listingStock}
                      currentUserCartListingIds={currentUserCartListingIds}
                      handleAddToCart={this.handleAddToCart}
                      addToCartInProgress={addToCartInProgress}
                      addToCartError={addToCartError}
                      allowBookings={allowBookings}
                      handleDiscountChange={this.handleDiscountChange}
                      discountCode={this.state.discountCode}
                      discountCodes={discountCodes}
                      fetchDiscountCodesInProgress={fetchDiscountCodesInProgress}
                      fetchDiscountCodesError={fetchDiscountCodesError}
                    />
                  )}
                  <SectionHostMaybe
                    listing={currentListing}
                    onContactUser={this.onContactUser}
                    currentUser={currentUser}
                    reviews={reviews}
                    disableContact
                  />
                  {showReviews ? (
                    <SectionReviews
                      reviews={reviews}
                      reviewsLength={reviewsLength}
                      fetchReviewsError={fetchReviewsError}
                      authorId={authorId}
                    />
                  ) : null}
                </div>
              </div>
              <SectionListings
                listings={similarListings}
                listingsLoaded={similarListingsLoaded}
                fetchListingsInProgress={fetchSimilarListingsInProgress}
                fetchListingsError={fetchSimilarListingsError}
                currentUser={currentUser}
                onAddToWishList={onAddToWishList}
                currentWishListListingId={currentWishListListingId}
                addToWishListInProgress={addToWishListInProgress}
                addToWishListError={addToWishListError}
              />
            </div>
            <ShippingAddressModal
              id="ShippingAddressModal"
              shippingId="shippingAddress"
              isOpen={this.state.showShippingAddress}
              onCloseModal={() => this.setState({ showShippingAddress: false })}
              onManageDisableScrolling={onManageDisableScrolling}
              onSubmit={this.handleShippingSubmit}
              inProgress={updateProfileInProgress}
              addressValidationsInProgress={addressValidationsInProgress}
              addressValidationsError={addressValidationsError}
              modalDescription={intl.formatMessage({
                id: 'ListingPage.shippingAddressText',
              })}
              initialValues={{ shippingAddress: { country: 'GB' } }}
            />
            <Modal
              id="ListingPage.enquiry"
              contentClassName={css.enquiryModalContent}
              isOpen={isAuthenticated && this.state.enquiryModalOpen}
              onClose={() => this.setState({ enquiryModalOpen: false })}
              usePortal
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <EnquiryForm
                className={css.enquiryForm}
                submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
                listingTitle={title}
                authorDisplayName={authorDisplayName}
                sendEnquiryError={sendEnquiryError}
                onSubmit={this.onSubmitEnquiry}
                inProgress={sendEnquiryInProgress}
              />
            </Modal>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
  addressValidationsInProgress: false,
  addressValidationsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  addressValidationsInProgress: bool.isRequired,
  addressValidationsError: propTypes.error,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    reviewsLength,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    similarListingIds,
    fetchSimilarListingsInProgress,
    fetchSimilarListingsError,
    discountCodes,
    fetchDiscountCodesInProgress,
    fetchDiscountCodesError,
  } = state.ListingPage;
  const { currentUser, currentUserCartListingIds, currentUserHasShippingAddress } = state.user;
  const {
    addToWishListInProgress,
    addToWishListError,
    currentWishListListingId,
  } = state.WishListPage;
  const { addToCartInProgress, addToCartError } = state.CartPage;
  const { updateInProgress: updateProfileInProgress } = state.ProfileSettingsPage;
  const { addressValidationsInProgress, addressValidationsError } = state.shipping;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const similarListings = getListingsById(state, similarListingIds);
  const similarListingsLoaded = similarListings && similarListings.length > 0;

  return {
    isAuthenticated,
    currentUser,
    currentUserHasShippingAddress,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    reviewsLength,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    similarListings,
    similarListingsLoaded,
    fetchSimilarListingsInProgress,
    fetchSimilarListingsError,
    addToWishListInProgress,
    addToWishListError,
    currentWishListListingId,
    addToCartInProgress,
    addToCartError,
    currentUserCartListingIds,
    discountCodes,
    fetchDiscountCodesInProgress,
    fetchDiscountCodesError,
    updateProfileInProgress,
    addressValidationsInProgress,
    addressValidationsError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message, processAlias) =>
    dispatch(sendEnquiry(listingId, message, processAlias)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onAddToWishList: listingId => dispatch(addToWishList(listingId)),
  onAddToCart: listingId => dispatch(addToCart(listingId)),
  onUpdateProfile: params => dispatch(updateProfile(params)),
  onAddressValidations: params => dispatch(addressValidations(params)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
  injectIntl
)(ListingPageComponent);

export default ListingPage;
