import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { UserCard } from '../../components';

import css from './ListingPage.module.css';

const SectionHostMaybe = props => {
  const { listing, onContactUser, currentUser, reviews, ...rest } = props;

  if (!listing.author) {
    return null;
  }

  // Get average number of all ratings
  const arrAvg = arr => arr.reduce((a, b) => a + b, 0) / arr.length;
  const getAvgRating = reviews && arrAvg(reviews.map(review => review.attributes.rating));
  const avgRating = parseInt(getAvgRating);

  return (
    <div id="host" className={css.sectionHost}>
      <h2 className={css.yourHostHeading}>
        <FormattedMessage id="ListingPage.yourHostHeading" />
      </h2>
      <UserCard
        {...rest}
        user={listing.author}
        currentUser={currentUser}
        onContactUser={onContactUser}
        reviews={reviews}
        avgRating={avgRating}
      />
    </div>
  );
};

export default SectionHostMaybe;
