import React from 'react';
import { array } from 'prop-types';
import { NamedLink } from '../../components';

import css from './ListingPage.module.css';

const TITLE_KEY = 'title';

const Breadcrumbs = props => {
  const { breadcrumbs } = props;

  return (
    <div className={css.breadcrumbsContainer}>
      <ul className={css.breadcrumbsList}>
        {breadcrumbs.map(crumb => {
          return (
            <li key={crumb.key} className={css.crumb}>
              {crumb.key === TITLE_KEY ? (
                crumb.label
              ) : (
                <NamedLink
                  className={css.crumbLink}
                  name="SearchPage"
                  to={{ search: crumb.search }}
                >
                  {crumb.label}
                </NamedLink>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

Breadcrumbs.defaultProps = {
  breadcrumbs: null,
};

Breadcrumbs.propTypes = {
  breadcrumbs: array.isRequired,
};

export default Breadcrumbs;
