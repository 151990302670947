/**
 * NOTE: This component is only used in ListingPage
 */

import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ListingReviews, NamedLink } from '../../components';

import css from './ListingPage.module.css';

const MIN_REVIEW_LENGTH = 3;

const SectionReviews = props => {
  const { reviews, reviewsLength, fetchReviewsError, authorId } = props;

  if (reviews?.length === 0) {
    return null;
  }

  const reviewsError = (
    <h2 className={css.errorText}>
      <FormattedMessage id="ListingPage.reviewsError" />
    </h2>
  );

  const showAllReviewsButton = authorId && reviewsLength > MIN_REVIEW_LENGTH;

  return (
    <div className={css.sectionReviews}>
      <h2 className={css.reviewsHeading}>
        <FormattedMessage id="ListingPage.reviewsHeading" values={{ length: reviews?.length }} />
      </h2>
      {fetchReviewsError ? reviewsError : null}
      <ListingReviews reviews={reviews} />
      {showAllReviewsButton ? (
        <div className={css.readAllReviews}>
          <NamedLink
            className={css.readAllReviewsText}
            name="ProfilePage"
            params={{ id: authorId }}
          >
            <FormattedMessage id="ListingPage.readAllReviews" />
          </NamedLink>
        </div>
      ) : null}
    </div>
  );
};

export default SectionReviews;
