/**
 * NOTE: This component is only used in ListingPage
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { CarouselCard, MobileCarousel, MobileCarouselCard } from '../../components';

import css from './ListingPage.module.css';

const SectionListings = props => {
  const {
    rootClassName,
    className,
    listings,
    listingsLoaded,
    fetchListingsInProgress,
    currentUser,
    onAddToWishList,
    currentWishListListingId,
    addToWishListInProgress,
    addToWishListError,
  } = props;

  const classes = classNames(rootClassName || css.sectionListings, className);

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  const cardProps = {
    currentUser,
    onAddToWishList,
    currentWishListListingId,
    addToWishListInProgress,
    addToWishListError,
  };

  return listingsLoaded ? (
    <div className={classes}>
      <h2 className={css.listingsTitle}>
        <FormattedMessage id="ListingPage.suggestedTitle" />
      </h2>
      {!fetchListingsInProgress ? (
        <div className={css.desktopListingsSlider}>
          {listings?.map(l => {
            return (
              <CarouselCard
                key={l.id.uuid}
                listing={l}
                renderSizes={cardRenderSizes}
                {...cardProps}
              />
            );
          })}
        </div>
      ) : null}
      {!fetchListingsInProgress ? (
        <div className={css.mobileListingsSlider}>
          <MobileCarousel>
            {listings?.map(l => {
              return (
                <MobileCarouselCard
                  key={l.id.uuid}
                  listing={l}
                  renderSizes={cardRenderSizes}
                  {...cardProps}
                />
              );
            })}
          </MobileCarousel>
        </div>
      ) : null}
    </div>
  ) : null;
};

SectionListings.defaultProps = { rootClassName: null, className: null, listings: null };

const { string, array, bool } = PropTypes;

SectionListings.propTypes = {
  rootClassName: string,
  className: string,

  // Listings
  listings: array.isRequired,
  listingsLoaded: bool.isRequired,
  fetchListingsInProgress: bool.isRequired,
  fetchListingsError: propTypes.error,
};

export default SectionListings;
