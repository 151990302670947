import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel } from '../../components';
import classNames from 'classnames';

import ImageSlider from './ImageSlider';
import ActionBarMaybe from './ActionBarMaybe';

import css from './ListingPage.module.css';

const SectionImages = props => {
  const {
    title,
    listing,
    isOwnListing,
    editParams,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
    isMobileLayout,
  } = props;

  // Slider states
  const [currentSlide, setCurrentSlide] = useState(0);
  const showSlider = listing?.images?.length > 1;

  const hasImages = listing?.images?.length > 0;
  const images = hasImages && listing.images;
  const highlightedImage = hasImages ? listing.images[currentSlide] : null;

  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  const actionBar = listing.id ? (
    <div onClick={e => e.stopPropagation()}>
      <ActionBarMaybe isOwnListing={isOwnListing} listing={listing} editParams={editParams} />
    </div>
  ) : null;

  const viewPhotosButton = hasImages ? (
    <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
      <FormattedMessage
        id="ListingPage.viewImagesButton"
        values={{ count: listing.images.length }}
      />
    </button>
  ) : null;

  const highlightedImageVariants = isMobileLayout
    ? ['landscape-crop', 'landscape-crop2x', 'landscape-crop4x', 'landscape-crop6x']
    : ['scaled-large', 'landscape-crop', 'landscape-crop2x'];

  return (
    <div className={classNames(css.sectionImages, { [css.sectionImagesWithSlider]: showSlider })}>
      {showSlider ? (
        <ImageSlider
          images={images}
          title={title}
          currentSlide={currentSlide}
          onSetCurrentSlide={setCurrentSlide}
        />
      ) : null}
      <div className={css.threeToTwoWrapper}>
        <div className={css.aspectWrapper} onClick={handleViewPhotosClick}>
          {actionBar}
          {viewPhotosButton}
          <ResponsiveImage
            rootClassName={css.rootForImage}
            alt={title}
            image={highlightedImage}
            variants={highlightedImageVariants}
          />
        </div>
      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel images={listing.images} selectedImageIndex={currentSlide} />
      </Modal>
    </div>
  );
};

export default SectionImages;
