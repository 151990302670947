import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { applyFeaturesUnit } from '../../util/data';
import { IconArrowHead } from '../../components';
import pick from 'lodash/pick';
import includes from 'lodash/includes';

import css from './ListingPage.module.css';

const MAX_FIELDS_LENGTH = 10;

const SELECT_TYPE = 'select';
const CHECKBOX_TYPE = 'checkbox';
const CHECKBOX_GROUP_TYPE = 'checkbox-group';

const findCheckboxValues = (options, selectedOptions) => {
  const values = options?.filter(option => includes(selectedOptions, option.key));
  const formattedValues = values?.map(value => value.label)?.join(', ');

  return formattedValues;
};

const Feature = ({ field, value }) => {
  return (
    <>
      <li className={css.feature}>
        <span className={css.featureField}>{field}</span>
        <span className={css.featureValue}>{value}</span>
      </li>
    </>
  );
};

const SectionFeaturesMaybe = props => {
  const { intl, listingFields, publicData } = props;

  if (!publicData) {
    return null;
  }

  // Intl messages
  const defaultCheckboxMessage = intl.formatMessage({
    id: 'ListingPage.defaultCheckboxMessage',
  });

  const listingFieldsKeys = listingFields?.map(field => {
    return field.key;
  });
  const listingFeatures = pick(publicData, listingFieldsKeys);
  const mappedListingFeatures = Object.keys(listingFeatures).map(feature => feature);

  // Only show the section if there's features added
  const showFeatures = mappedListingFeatures?.length > 0;

  const isMaxVisibleFeatures = mappedListingFeatures?.length >= MAX_FIELDS_LENGTH;
  const [visibleFeatures, setVisibleFeatures] = useState(
    isMaxVisibleFeatures ? mappedListingFeatures.slice(0, MAX_FIELDS_LENGTH) : mappedListingFeatures
  );

  // Declare when showMore and showLess will be visible
  const showMoreVisible =
    isMaxVisibleFeatures && visibleFeatures?.length !== mappedListingFeatures?.length;
  const showLessVisible =
    mappedListingFeatures?.length > MAX_FIELDS_LENGTH &&
    visibleFeatures?.length > MAX_FIELDS_LENGTH;

  return showFeatures ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.featuresTitle" />
      </h2>
      <ul className={css.features}>
        {visibleFeatures.map((key, index) => {
          // Current field
          const currentListingField = listingFields?.find(field => field.key === key);
          const publicDataValue = listingFeatures[key];

          // Field types
          const isSelectField = currentListingField?.type === SELECT_TYPE;
          const isCheckboxField = currentListingField?.type === CHECKBOX_TYPE;
          const isCheckboxGroupField = currentListingField?.type === CHECKBOX_GROUP_TYPE;

          // Values by field types
          const selectValue = currentListingField?.values?.find(
            value => value.key === publicDataValue
          )?.label;
          const checkboxValue = publicDataValue && defaultCheckboxMessage;
          const checkboxGroupValue = findCheckboxValues(
            currentListingField?.values,
            publicDataValue
          );
          const defaultValue = applyFeaturesUnit(key, publicDataValue);

          // Final value
          const finalValue = isSelectField
            ? selectValue // ↑ From <SelectField />
            : isCheckboxField
            ? checkboxValue // ↑ From <CheckboxField />
            : isCheckboxGroupField
            ? checkboxGroupValue // ↑ From <CheckboxGroupField />
            : defaultValue;

          if (!finalValue) {
            return null;
          }

          return (
            <Feature
              key={`${index}_${key}`}
              field={intl.formatMessage({ id: currentListingField.labelMsgId })}
              value={finalValue}
            />
          );
        })}
      </ul>
      {showMoreVisible ? (
        <div
          className={css.showMoreContainer}
          onClick={() => setVisibleFeatures(mappedListingFeatures)}
        >
          <FormattedMessage
            id="ListingPage.showMore"
            values={{ count: mappedListingFeatures?.length - MAX_FIELDS_LENGTH }}
          />
          <IconArrowHead className={css.showMoreIcon} direction="down" />
        </div>
      ) : null}
      {showLessVisible ? (
        <div
          className={css.showMoreContainer}
          onClick={() => setVisibleFeatures(mappedListingFeatures.slice(0, MAX_FIELDS_LENGTH))}
        >
          <FormattedMessage id="ListingPage.showLess" />
          <IconArrowHead className={css.showMoreIcon} direction="up" />
        </div>
      ) : null}
    </div>
  ) : null;
};

export default SectionFeaturesMaybe;
